<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="12" lg="12">
        <template>
          <div
            class="d-flex flex-row flex-fill justify-center mt-16 animate__animated animate__fadeIn"
          >
            <img
              :src="require('@/assets/calendar-1.svg')"
              style="width: 120px;"
            />
          </div>
          <div class="d-flex flex-column flex-fill align-center mt-5">
            <span
              class="font font-md font-weight-medium pt-2 animate__animated animate_fadeInDown"
            >
              Download transactions in formats like excel, csv
            </span>

            <v-chip
              color="primary"
              label
              small
              class=" font-sm font font-weight-medium font-size-sm my-2"
              >Instructions to Follow</v-chip
            >
            <span class="font font-md font-weight-medium font-size-sm"
              >Choose <kbd>a date range</kbd> to start downloading
              transactions</span
            >

            <p>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                class="font font-weight-medium"
                min-width="auto"
                :nudge-left="120"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-model="dates"
                    v-bind="attrs"
                    v-on="on"
                    label
                    :close="dates.length > 0"
                    @click:close="(dates = []), (chosenDateRange = [])"
                    :color="dates.length > 0 ? 'primary' : 'white'"
                    class=" box-shadow-light mt-5 font text-uppercase font-weight-medium"
                  >
                    <i class="material-icons-outlined font-size-md mr-2"
                      >event</i
                    >
                    {{ dates.length > 0 ? dateRangeText : 'Choose date range' }}
                  </v-chip>
                </template>
                <v-date-picker
                  class="font font-weight-medium font-size-sm"
                  v-model="dates"
                  :max="new Date().toISOString().substring(0, 10)"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    class="font font-weight-medium"
                    text
                    color="primary"
                    @click="
                      (menu = false), (dates = []), (chosenDateRange = [])
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    @click="setFilteredDate"
                    text
                    class="font font-weight-medium"
                    color="primary"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </p>
            <template v-if="chosenDateRange.length > 0">
              <span class=" font font-md font-weight-medium mb-2"
                >Click any of the format to download
              </span>
              <p>
                <v-avatar
                  v-ripple
                  tile
                  @click="downloadFile('xlsx')"
                  class="box-shadow-light mx-2"
                  style="cursor:pointer;"
                >
                  <img src="@/assets/excel.png" style="width: 40px;" />
                </v-avatar>
                <v-avatar
                  v-ripple
                  tile
                  @click="isLoading ? '' : downloadFile('csv')"
                  class="box-shadow-light mx-2"
                  style="cursor:pointer;"
                >
                  <img src="@/assets/csv.svg" style="width: 40px;" />
                </v-avatar>
              </p>
            </template>
            <v-alert
              type="error"
              dense
              v-if="isInvalidDate"
              outlined
              clearable
              class="font font-weight-medium font-size-md "
            >
              Invalid date provided, kindly check again.
            </v-alert>
            <span v-if="isLoading">
              <v-progress-circular
                indeterminate
                color="primary"
                size="30"
                width="2"
              />
            </span>
          </div>
        </template>
      </v-col>
      <SnackBar
        :showSnackBar="showSnackBar"
        :message="message"
        :status="status"
      />
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { loadComponent } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import SnackBarMixins from '../../../../mixins/SnackBarMIxins';
export default {
  name: 'ExportTransactions',
  data() {
    return {
      menu: false,
      dates: [],
      chosenDateRange: [],
      isInvalidDate: false,
    };
  },
  components: {
    SnackBar: loadComponent(`component/SnackBar`),
  },
  mixins: [SnackBarMixins],
  computed: {
    ...mapGetters({
      isLoading: 'getIsLoading',
    }),
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    setFilteredDate() {
      const [startDate, endDate] = this.dates;
      if (moment(startDate).isAfter(moment(endDate))) {
        this.isInvalidDate = true;
        this.$refs.menu.save([]);
      } else {
        this.isInvalidDate = false;
        this.$refs.menu.save(this.dates);
        this.chosenDateRange = this.dates;
      }
    },
    downloadFile(ext) {
      const fileName = `trans-${this.chosenDateRange.join('-')}`;
      const [startDate, endDate] = this.dates;
      this.$store.dispatch('transactions/download', {
        fileName,
        ext,
        startDate,
        endDate,
      });
    },
  },
};
</script>

<style scoped></style>
